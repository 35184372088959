<template>
  <div>
    <el-form
      ref="notificationForm"
      :model="notificationParam"
      label-width="150px"
    >
      <el-form-item label="Title(English)" prop="titleEn">
        <el-input
          v-model="notificationParam.titleEn"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Title(简体中文)" prop="titleZh">
        <el-input
          v-model="notificationParam.titleZh"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Body(English)" prop="bodyEn">
        <el-input
          v-model="notificationParam.bodyEn"
          auto-complete="off"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="Body(简体中文)" prop="bodyZh">
        <el-input
          v-model="notificationParam.bodyZh"
          auto-complete="off"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="Deep Link Type">
        <el-select
          v-model="notificationParam.deeplinkParam.path"
          @change="clearParam()"
        >
          <el-option
            v-for="item in deeplinkOptions"
            :value="item.path"
            :key="item.path"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Merchant"
        v-if="
          notificationParam.deeplinkParam.path == ProductDetailLink ||
          notificationParam.deeplinkParam.path == DigitalProductDetailLink ||
          notificationParam.deeplinkParam.path == MerchantDetailLink
        "
      >
        <el-select v-model="merchantId">
          <el-option
            v-for="item in merchantOptions"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Category"
        v-if="notificationParam.deeplinkParam.path == CategoryDetailLink"
      >
        <el-select v-model="categoryId">
          <el-option
            v-for="item in categoryOptions"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Product"
        v-if="
          (notificationParam.deeplinkParam.path == ProductDetailLink ||
            notificationParam.deeplinkParam.path == DigitalProductDetailLink) &&
          !productOptionsLoading
        "
      >
        <el-select
          v-model="notificationParam.deeplinkParam.params.id"
          collapse-tags
          @change="findOngoingBulk"
        >
          <div class="el-input">
            <input
              type="text"
              class="el-input__inner"
              v-model="productSearchValue"
              @keyup="
                handleFilter(
                  productOptions,
                  'productOptionsFiltered',
                  productSearchValue,
                  'notificationParam.deeplinkParam.params.id'
                )
              "
            />
          </div>
          <el-option
            v-for="item in productOptionsFiltered"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Include Bulk" v-if="allowBulk && bulkId && notificationParam.deeplinkParam.path == ProductDetailLink">
        <el-checkbox v-model="includeBulkId" ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button @click="$emit('finish')">Cancel</el-button>
        <el-button type="primary" @click="submitSendNotification()"
          >Send</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  sendAll,
  sendToConsumer,
  sendAllUnderCaptain,
} from '@/api/notification';
import { fetchOptions as fetchMerchantOptions } from '@/api/merchant';
import { fetchOptions as fetchProductOptions } from '@/api/product';
import { fetchOptions as fetchCategoryOptions } from '@/api/productCate';
import { findOngoing } from '@/api/bulk';

const DefaultNotificationParam = {
  id: null,
  titleEn: null,
  titleZh: null,
  bodyEn: null,
  bodyZh: null,
  apnType: null,
  deeplinkParam: {
    path: null,
    params: {
      id: null,
    },
  },
};
const ProductDetailLink = '/link/v1/productDetail';
const DigitalProductDetailLink = '/link/v1/digitalProductDetail';
const MerchantDetailLink = '/link/v1/merchantDetail';
const CategoryDetailLink = '/link/v1/categoryDetail';
export default {
  name: 'notificationForm',
  props: {
    type: String,
    id: String,
  },
  data() {
    return {
      notificationParam: { ...DefaultNotificationParam },
      deeplinkOptions: [
        {
          name: 'None',
          path: null,
        },
        {
          name: 'Product',
          path: ProductDetailLink,
        },
        {
          name: 'Digital Product',
          path: DigitalProductDetailLink,
        },
        // {
        //   name: 'Merchant',
        //   path: MerchantDetailLink,
        // },
        // {
        //   name: 'Category',
        //   path: CategoryDetailLink,
        // },
      ],

      merchantId: null,
      merchantOptions: null,

      categoryId: null,
      categoryOptions: null,

      productSearchValue: null,
      productOptions: null,
      productOptionsFiltered: null,
      productOptionsLoading: true,

      sendMethod: null,

      includeBulkId: false,
      bulkId: null,
      allowBulk: false,

      ProductDetailLink,
      DigitalProductDetailLink,
      MerchantDetailLink,
      CategoryDetailLink,
    };
  },
  watch: {
    merchantId(newValue) {
      if (newValue != null) this.fetchProductOptions();
    },
  },
  created() {
    this.fetchMerchantOptions();
    this.fetchCategoryOptions();
    if (this.type == 'all') {
      this.sendMethod = sendAll;
      this.notificationParam.apnType = null;
      this.allowBulk = false;
    } else if (this.type == 'single') {
      this.sendMethod = sendToConsumer;
      this.notificationParam.apnType = 'single';
      this.notificationParam.id = this.id;
      this.allowBulk = true;
    } else if (this.type == 'captain') {
      this.sendMethod = sendAllUnderCaptain;
      this.notificationParam.apnType = null;
      this.notificationParam.id = this.id;
      this.allowBulk = true;
    }
  },
  methods: {
    submitSendNotification() {
      const notificationParam = { ...this.notificationParam };
      if (notificationParam.deeplinkParam.path == MerchantDetailLink) {
        notificationParam.deeplinkParam.params.id = this.merchantId;
        notificationParam.deeplinkParam.params.name = this.merchantOptions.find(
          (x) => x.id == this.merchantId,
        ).name;
      } else if (notificationParam.deeplinkParam.path == CategoryDetailLink) {
        notificationParam.deeplinkParam.params.id = this.categoryId;
        notificationParam.deeplinkParam.params.name = this.categoryOptions.find(
          (x) => x.id == this.categoryId,
        ).name;
      } else if (notificationParam.deeplinkParam.path == null) {
        delete notificationParam.deeplinkParam;
      }
      if (this.allowBulk && this.includeBulkId && this.bulkId) {
        notificationParam.deeplinkParam.params.bulkId = this.bulkId;
      }
      this.$confirm('Send Notification?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.sendMethod(notificationParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
        });
        this.$emit('finish');
      });
    },
    fetchMerchantOptions() {
      fetchMerchantOptions().then((response) => {
        this.merchantOptions = response.data;
      });
    },
    fetchCategoryOptions() {
      fetchCategoryOptions().then((response) => {
        this.categoryOptions = response.data;
        this.categoryOptions.sort((x, y) => (x.name > y.name ? 1 : -1));
      });
    },
    fetchProductOptions() {
      this.productOptionsLoading = true;
      const params = {
        merchantId: this.merchantId,
        productType: null,
      };
      if (this.notificationParam.deeplinkParam.path == ProductDetailLink) {
        params.productType = 'PHYSICAL_PRODUCT';
      } else if (
        this.notificationParam.deeplinkParam.path == DigitalProductDetailLink
      ) {
        params.productType = 'DIGITAL_PRODUCT';
      } else return;
      fetchProductOptions(params).then((response) => {
        this.productOptions = response.data;
        this.productOptionsFiltered = response.data;
        this.productOptionsLoading = false;
      });
    },
    findOngoingBulk() {
      if (!this.allowBulk) return;
      const productId = this.notificationParam.deeplinkParam.params.id;
      const findBulkParam = {
        productId,
        consumerId: null,
        captainId: null,
      };
      if (this.type == 'single') {
        findBulkParam.consumerId = this.id;
      } else if (this.type == 'captain') {
        findBulkParam.captainId = this.id;
      }
      findOngoing(findBulkParam).then((response) => {
        this.bulkId = response.data;
      });
    },
    handleFilter(src, dst, keyword) {
      if (keyword == null || keyword == '') return;
      const matched = src.filter((x) => x.name.toLowerCase().includes(keyword.toLowerCase()));
      const unmatched = src.filter(
        (x) => !x.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      this[dst] = matched.concat(unmatched);
    },
    clearParam() {
      this.merchantId = null;
      this.notificationParam.deeplinkParam.params = { id: null };
      this.productOptionsLoading = true;
    },
  },
};
</script>
