var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"notificationForm",attrs:{"model":_vm.notificationParam,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"Title(English)","prop":"titleEn"}},[_c('el-input',{attrs:{"auto-complete":"off"},model:{value:(_vm.notificationParam.titleEn),callback:function ($$v) {_vm.$set(_vm.notificationParam, "titleEn", $$v)},expression:"notificationParam.titleEn"}})],1),_c('el-form-item',{attrs:{"label":"Title(简体中文)","prop":"titleZh"}},[_c('el-input',{attrs:{"auto-complete":"off"},model:{value:(_vm.notificationParam.titleZh),callback:function ($$v) {_vm.$set(_vm.notificationParam, "titleZh", $$v)},expression:"notificationParam.titleZh"}})],1),_c('el-form-item',{attrs:{"label":"Body(English)","prop":"bodyEn"}},[_c('el-input',{attrs:{"auto-complete":"off","rows":3},model:{value:(_vm.notificationParam.bodyEn),callback:function ($$v) {_vm.$set(_vm.notificationParam, "bodyEn", $$v)},expression:"notificationParam.bodyEn"}})],1),_c('el-form-item',{attrs:{"label":"Body(简体中文)","prop":"bodyZh"}},[_c('el-input',{attrs:{"auto-complete":"off","rows":3},model:{value:(_vm.notificationParam.bodyZh),callback:function ($$v) {_vm.$set(_vm.notificationParam, "bodyZh", $$v)},expression:"notificationParam.bodyZh"}})],1),_c('el-form-item',{attrs:{"label":"Deep Link Type"}},[_c('el-select',{on:{"change":function($event){return _vm.clearParam()}},model:{value:(_vm.notificationParam.deeplinkParam.path),callback:function ($$v) {_vm.$set(_vm.notificationParam.deeplinkParam, "path", $$v)},expression:"notificationParam.deeplinkParam.path"}},_vm._l((_vm.deeplinkOptions),function(item){return _c('el-option',{key:item.path,attrs:{"value":item.path,"label":item.name}})}),1)],1),(
        _vm.notificationParam.deeplinkParam.path == _vm.ProductDetailLink ||
        _vm.notificationParam.deeplinkParam.path == _vm.DigitalProductDetailLink ||
        _vm.notificationParam.deeplinkParam.path == _vm.MerchantDetailLink
      )?_c('el-form-item',{attrs:{"label":"Merchant"}},[_c('el-select',{model:{value:(_vm.merchantId),callback:function ($$v) {_vm.merchantId=$$v},expression:"merchantId"}},_vm._l((_vm.merchantOptions),function(item){return _c('el-option',{key:item.id,attrs:{"value":item.id,"label":item.name}})}),1)],1):_vm._e(),(_vm.notificationParam.deeplinkParam.path == _vm.CategoryDetailLink)?_c('el-form-item',{attrs:{"label":"Category"}},[_c('el-select',{model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}},_vm._l((_vm.categoryOptions),function(item){return _c('el-option',{key:item.id,attrs:{"value":item.id,"label":item.name}})}),1)],1):_vm._e(),(
        (_vm.notificationParam.deeplinkParam.path == _vm.ProductDetailLink ||
          _vm.notificationParam.deeplinkParam.path == _vm.DigitalProductDetailLink) &&
        !_vm.productOptionsLoading
      )?_c('el-form-item',{attrs:{"label":"Product"}},[_c('el-select',{attrs:{"collapse-tags":""},on:{"change":_vm.findOngoingBulk},model:{value:(_vm.notificationParam.deeplinkParam.params.id),callback:function ($$v) {_vm.$set(_vm.notificationParam.deeplinkParam.params, "id", $$v)},expression:"notificationParam.deeplinkParam.params.id"}},[_c('div',{staticClass:"el-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productSearchValue),expression:"productSearchValue"}],staticClass:"el-input__inner",attrs:{"type":"text"},domProps:{"value":(_vm.productSearchValue)},on:{"keyup":function($event){return _vm.handleFilter(
                _vm.productOptions,
                'productOptionsFiltered',
                _vm.productSearchValue,
                'notificationParam.deeplinkParam.params.id'
              )},"input":function($event){if($event.target.composing){ return; }_vm.productSearchValue=$event.target.value}}})]),_vm._l((_vm.productOptionsFiltered),function(item){return _c('el-option',{key:item.id,attrs:{"value":item.id,"label":item.name}})})],2)],1):_vm._e(),(_vm.allowBulk && _vm.bulkId && _vm.notificationParam.deeplinkParam.path == _vm.ProductDetailLink)?_c('el-form-item',{attrs:{"label":"Include Bulk"}},[_c('el-checkbox',{model:{value:(_vm.includeBulkId),callback:function ($$v) {_vm.includeBulkId=$$v},expression:"includeBulkId"}})],1):_vm._e(),_c('el-form-item',[_c('el-button',{on:{"click":function($event){return _vm.$emit('finish')}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitSendNotification()}}},[_vm._v("Send")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }