<template>
  <div style="margin-top: 50px">
    <el-card class="filter-container" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
          style="float: right"
          @click="getList()"
          type="primary"
          size="small"
        >
          查询结果
        </el-button>
        <el-button
          style="float: right; margin-right: 15px"
          @click="handleResetSearch()"
          size="small"
        >
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form
          :inline="true"
          :model="listQuery"
          size="small"
          label-width="140px"
        >
          <el-form-item label="Email：">
            <el-input v-model="listQuery.email" placeholder="email"></el-input>
          </el-form-item>
          <el-form-item label="Nickname：">
            <el-input
              v-model="listQuery.nickname"
              placeholder="Nickname"
            ></el-input>
          </el-form-item>
          <el-form-item label="Community: ">
            <el-select
              v-model="listQuery.captainId"
              placeholder="Select Captain"
            >
              <el-option
                v-for="item in captainOptions"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Start time: " prop="startTime">
            <el-date-picker
              v-model="listQuery.createDateStart"
              type="datetime"
              placeholder="Start Date"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="End time: " prop="endTime">
            <el-date-picker
              v-model="listQuery.createDateEnd"
              type="datetime"
              placeholder="End Date"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="table-container">
      <el-table
        ref="consumerTable"
        :data="list"
        style="width: 100%"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Email" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.email }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Nickname" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.nickname }}</p>
          </template>
        </el-table-column>
        <el-table-column label="First Name" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.firstName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Last Name" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.lastName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Status" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.status }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Create Time" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.createTime }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Captain" align="center" width="250">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.captain == null
                  ? "--No Captain--"
                  : scope.row.captain.nickname
              }}
            </p>
            <p>
              <el-button
                v-if="scope.row.captain != null"
                size="small"
                @click="reassignCaptain(scope.row)"
              >
                Reassign Captain
              </el-button>
            </p>
            <p>
              Community:{{
                scope.row.captain == null
                  ? "--No Captain--"
                  : scope.row.captain.name
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Current Address" align="center">
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <el-table-column label="Operations" align="center">
          <template slot-scope="scope">
            <p>
              <el-button
                size="small"
                type="danger"
                @click="disableConsumer(scope.row.id)"
                v-if="scope.row.status != 0"
                >Disable</el-button
              >
            </p>
            <p>
              <el-button
                size="small"
                @click="createCaptainFromConsumer(scope.row)"
                v-if="scope.row.status != 0"
                >Create Captain</el-button
              >
            </p>
            <p>
              <el-button
                size="small"
                @click="sendNotification(scope.row)"
                v-if="scope.row.status != 0"
                >Send Notification</el-button
              >
            </p>
            <p>
              <el-button
                size="small"
                type="success"
                @click="showAddPromo(scope.row)"
                v-if="scope.row.status != 0"
                >Add Promo</el-button
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-card>
      <el-button @click="setPopupLowerVersion(true)" size="mini" type="primary"
        >LowerPopup设置为true</el-button
      >
      <el-button @click="setPopupLowerVersion(false)" size="mini" type="primary"
        >LowerPopup设置为false</el-button
      >
    </el-card>
    <el-dialog
      title="Reassign Captain"
      :visible.sync="assignCaptainVisible"
      width="50%"
    >
      <el-form
        ref="createCaptainForm"
        :model="captainAssignParam"
        label-width="120px"
      >
        <el-form-item label="Captain">
          <el-select v-model="captainAssignParam.captainId">
            <el-option
              v-for="(item, index) in captainOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button size="small" @click="submitReassignCaptain()"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Create Captain"
      :visible.sync="createCaptainVisible"
      width="50%"
    >
      {{ captainApplication }}
      <el-form
        ref="createCaptainForm"
        :model="captainApplication"
        label-width="120px"
      >
        <el-form-item
          label="First Name"
          prop="firstName"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.firstName"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Last Name"
          prop="lastName"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.lastName"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Email"
          prop="email"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.email"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Phone"
          prop="phone"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.phone"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Address"
          prop="address"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.address"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="City" prop="city">
          <el-input
            v-model="captainApplication.city"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Province" prop="province">
          <el-input
            v-model="captainApplication.province"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Postal Code" prop="postalCode">
          <el-input
            v-model="captainApplication.postalCode"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Work Time Pref"
          prop="workTimePreference"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.workTimePreference"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Temp. Storage"
          prop="temporaryStorage"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-radio-group v-model="captainApplication.temporaryStorage">
            <el-radio :label="true">Yes</el-radio>
            <el-radio :label="false">No</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="Delivery Pref"
          prop="deliveryPreference"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="captainApplication.deliveryPreference"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Zone Pref"
          prop="zonePreference"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="captainApplication.zonePreference"
            multiple
            style="width: 80%"
            collapse-tags
          >
            <el-option
              v-for="(item, index) in zoneOptions"
              :key="index"
              :label="item"
              :value="item"
              style="width: 80%"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Category Pref"
          prop="categoryPreference"
          :rules="[
            {
              required: true,
              message: 'Field can\'t be empty',
              trigger: 'blur',
            },
          ]"
        >
          <div
            v-for="(item, index) in captainApplication.categoryPreference"
            v-bind:key="index"
          >
            <el-form-item>
              <el-input
                v-model="captainApplication.categoryPreference[index]"
              ></el-input>
              <el-button
                @click="captainApplication.categoryPreference.splice(index, 1)"
                >Delete</el-button
              >
            </el-form-item>
          </div>
          <el-button @click="captainApplication.categoryPreference.push('')"
            >Add</el-button
          >
        </el-form-item>
        <el-form-item label="Zone Detail" prop="zoneDetail">
          <el-input
            v-model="captainApplication.zoneDetail"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Profile Picture" prop="profilePicture">
          <single-upload
            v-model="captainApplication.profilePicture"
            auto-complete="off"
          ></single-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitApplication()" align="center"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="'Send Notification To: ' + notificationConsumerName"
      :visible.sync="notificationVisible"
      width="30%"
    >
      <notification-form
        type="single"
        :id="notificationConsumerId"
        @finish="notificationVisible = false"
      ></notification-form>
    </el-dialog>

    <el-dialog title="Add Promo" :visible.sync="promo.addPromoVisible" width="30%">
      <el-form>
        <el-form-item label="Amount">
          <el-input v-model="promo.addAmount"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetPromo">Cancel</el-button>
          <el-button type="primary" @click="addPromoSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Confirm Operation"
      :visible.sync="promo.confirmPromoVisible"
      width="30%" v-if="promo.consumer"
    >
      <p>Nickname:{{ promo.consumer.nickname }}</p>
      <p>Email: {{ promo.consumer.email }}</p>
      <p>
        Before: <b style="color: #ff0000">{{ "$" + promo.beforeAmount }}</b>
      </p>
      <p>
        After: <b style="color: #059504">{{ "$" + promo.afterAmount }}</b>
      </p>
      <p style="align: center">
        <el-button @click="resetPromo">Cancel</el-button>
        <el-button type="primary" @click="confirmPromoSubmit"
          >Confirm</el-button
        >
      </p>
    </el-dialog>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.pageSize"
        :page-sizes="[5, 10, 15, 20]"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  list as fetch,
  disableById,
  createCaptain,
  setPopupLowerVersion,
  getPromoAmount,
  setPromoAmount,
} from '@/api/consumer';
import { fetchActive as fetchCaptain, reassignCaptain } from '@/api/captain';
import SingleUpload from '@/components/Upload/singleUpload';
import NotificationForm from '../../notification/NotificationForm.vue';

const defaultApplication = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  address: null,
  city: null,
  province: null,
  postalCode: null,
  workTimePreference: null,
  temporaryStorage: true,
  deliveryPreference: null,
  zoneDetail: null,
  profilePicture: null,
  zonePreference: [],
  categoryPreference: [],
};
const defaultAssignParam = {
  captainId: null,
  consumerId: null,
};
const defaultListQuery = {
  page: 1,
  count: 10,
  status: 1,
  email: null,
  nickname: null,
  captainId: null,
  createDateStart: null,
  createDateEnd: null,
  isAdmin: false,
};
const defaultPromo = {
  consumer: null,
  addAmount: null,
  afterAmount: null,
  beforeAmount: null,
  addPromoVisible: false,
  confirmPromoVisible: false,
};
export default {
  name: 'ConsumerList',
  components: { SingleUpload, NotificationForm },
  props: {
    queryType: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      list: null,
      listLoading: true,
      createCaptainVisible: false,
      captainApplication: { ...defaultApplication },
      zoneOptions: [
        'City of Toronto',
        'Markham',
        'North York',
        'Scarborough',
        'King',
        'Vaughan',
        'NewMarket',
        'Aurora',
        'Richmond Hill',
        'Georgina',
        'Brock',
        'Uxbridge',
        'Scugog',
        'Pickering',
        'Ajax',
        'Whitby',
        'Oshawa',
        'Clarington',
        'Missisauga',
        'Brampton',
        'Caledon',
        'Milton',
        'Burlington',
        'Halton Hills',
        'Oakville',
        'London',
        'Waterloo-Kitchener',
      ],
      categoryOptions: [
        'Floral',
        'Home Supplies&Appliances',
        'Produce',
        'Meat&Seafood',
        'Deli',
        'Bakery',
        'Drinks',
        'Pantry',
        'Frozen',
        'Beauty&Health',
        'Pets',
      ],
      createCaptainConsumerId: null,
      captainOptions: [],
      captainAssignParam: { ...defaultAssignParam },
      assignCaptainVisible: false,

      listQuery: { ...defaultListQuery },
      total: null,

      notificationVisible: false,
      notificationConsumerId: null,
      notificationConsumerName: null,

      promo: { ...defaultPromo },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      if (this.queryType == 'all') {
        this.listQuery.status = null;
      } else if (this.queryType == 'active') {
        this.listQuery.status = 1;
      }
      const listQuery = { ...this.listQuery };
      listQuery.page -= 1;
      fetch(listQuery).then((response) => {
        this.list = response.data.data;
        this.total = response.data.recordsTotal;
        this.listLoading = false;
      });
      fetchCaptain().then((response) => {
        this.captainOptions = response.data;
      });
    },
    disableConsumer(id) {
      this.$confirm('Disable Consumer?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        disableById(id).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    createCaptainFromConsumer(consumer) {
      this.captainApplication = { ...defaultApplication };
      this.createCaptainConsumerId = consumer.id;
      const fields = [
        'firstName',
        'lastName',
        'email',
        'phone',
        'address',
        'city',
        'province',
        'postalCode',
      ];
      for (const i in fields) {
        this.captainApplication[fields[i]] = consumer[fields[i]];
      }
      this.createCaptainVisible = true;
    },
    submitApplication() {
      this.$confirm('Create Captain?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        createCaptain(
          this.createCaptainConsumerId,
          this.captainApplication,
        ).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.createCaptainVisible = false;
        });
      });
    },
    reassignCaptain(consumer) {
      this.captainAssignParam.consumerId = consumer.id;
      this.assignCaptainVisible = true;
    },
    submitReassignCaptain(consumer) {
      reassignCaptain(this.captainAssignParam).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
      this.captainAssignParam = { ...defaultAssignParam };
      this.assignCaptainVisible = false;
    },
    sendNotification(consumer) {
      this.notificationConsumerId = consumer.id;
      this.notificationConsumerName = consumer.email;
      this.notificationVisible = true;
    },
    handleSizeChange(val) {
      this.listQuery.page = 1;
      this.listQuery.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleResetSearch() {
      this.listQuery.email = null;
      this.listQuery.nickname = null;
      this.listQuery.captainId = null;
      this.listQuery.createDateStart = null;
      this.listQuery.createDateEnd = null;
    },
    setPopupLowerVersion(value) {
      setPopupLowerVersion({ value }).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
      });
    },
    showAddPromo(consumer) {
      this.promo = { ...defaultPromo };
      this.promo.consumer = consumer;
      this.promo.addPromoVisible = true;
    },
    addPromoSubmit() {
      let addAmountFloat = parseFloat(this.promo.addAmount);
      if (addAmountFloat == null) {
        this.$message({
          message: '请检查amount格式',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      addAmountFloat = parseFloat(addAmountFloat.toFixed(2));
      getPromoAmount({ consumerId: this.promo.consumer.id }).then((response) => {
        this.promo.beforeAmount = response.data.amount.amount;
        this.promo.afterAmount = this.promo.beforeAmount + addAmountFloat;
        this.promo.addPromoVisible = false;
        this.promo.confirmPromoVisible = true;
      });
    },
    confirmPromoSubmit() {
      const param = {
        consumerId: this.promo.consumer.id,
        amount: this.promo.afterAmount,
      };
      setPromoAmount(param).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.promo = { ...defaultPromo };
      });
    },
    resetPromo() {
      this.promo = { ...defaultPromo };
    },
  },
};
</script>
