<template>
  <consumer-list :query-type="queryType"> </consumer-list>
</template>
<script>
import ConsumerList from './ConsumerList.vue';

export default {
  name: 'activeConsumer',
  components: { ConsumerList },
  data() {
    return {
      queryType: 'active',
    };
  },
};
</script>
